import { useState, useEffect } from 'react'
import Calendar from "../components/Booking/Calendar"
import BookingDatePane from "../components/Booking/BookingDatePane"
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';

export default function Book(props) {

  const { t, i18n } = useTranslation();
  const [bookingDate, setBookingDate] = useState();
  const [chosenDate, setChosenDate] = useState();
  const navigate = useNavigate();

  useEffect(() => {

    if(bookingDate) {
      
      let d = bookingDate.year + '-' + bookingDate.month + '-' + bookingDate.day
      setChosenDate(dayjs(d))

    }        
  }, [bookingDate]);

  return (
    <div className="pt-12">
      
      <div className="text-center mt-8 mb:0 sm:mb-8">
        <h4 className="text-xl font-bold">
          {t('choose_date')}
        </h4>
      </div>      
      
      <div className="flex flex-col md:flex-row max-w-screen-lg md:gap-5 mx-auto mb-8 relative">
        <div className="flex-grow transition">
          <Calendar setBookingDate={setBookingDate} />
        </div>
        {
          chosenDate &&
          <BookingDatePane chosenDate={chosenDate} setChosenDate={setChosenDate}/>
        }
      </div>

    </div>
  );
}