import { Fragment, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/solid"
import MobileNav from "./MobileNav"
import { Menu, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next';
import { nav_links, nav_booking_links } from '../utils/helpers';

export default function Nav(props) {

  const { t, i18n } = useTranslation();    

  const changeLang = () => {
    if(i18n.language == 'zh') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('zh');
    }
    localStorage.setItem('tt_lang', i18n.language);
  };

  const currentLang = () => {
    return i18n.language;
  }

  return (
    <div className="w-full fixed z-[999] p-5 bg-black/5 backdrop-blur">
      <div className="flex justify-between items-center">
        <div className="w-[300px]">
          <Link to="/home"><img src="/img/logo.svg" /></Link>
        </div>      

        <div className="hidden lg:flex lg:items-center gap-3 font-medium">

          <Menu as="div" className={`relative navBtnClass`}>
            <div>
              <Menu.Button className="flex items-center">
                {t('booking')}
                <ChevronDownIcon
                  className="ml-2 w-4"                  
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="flex flex-col absolute right-0 mt-2 w-56 rounded-md bg-base shadow-lg ring-1 ring-gray-700 text-right">
                {
                  nav_booking_links.map((link,i) => (
                    <Menu.Item key={i} className="text-white hover:bg-gray-800 p-2 px-3">
                      {({ active }) => (
                        <Link to={link.href}>
                          {t(link.label)}
                        </Link>
                      )}
                    </Menu.Item> 
                  ))
                }
              </Menu.Items>
            </Transition>
          </Menu>

          {
            nav_links.map((link,i) => (
              <a key={i} href={link.href} className="navBtnClass">{t(link.label)}</a>
            ))
          }
          
          <button onClick={() => changeLang()} className="navBtnClass">
            {
              currentLang() == 'en' ? '中文' : 'EN'
            }
          </button>
        </div>

        <MobileNav />

      </div>
    </div>
  );
}