import { useState, useEffect } from 'react'
import { selectStyle, classNames, delay, Spinner, apiClient } from '../utils/helpers';
import ReactSelect from 'react-select';
import Countdown from 'react-countdown';
import { countries_en } from '../utils/countries';

import {
  CheckIcon,
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export default function VerifyPhone(props) {

  const { t, i18n } = useTranslation();
  const [form, setForm] = useState(
    {
      country:'86',
      phone:''
    });
  const [error,setError] = useState('');
  const [smsSent,setSmsSent] = useState(false);
  const [smsLock, setSmsLock] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCodes, setCountryCodes] = useState();

  const smsCountdown = 30000

  const countdown = ({ hours, minutes, seconds, completed }) => {
    if (completed) {      
      setSmsLock(false)
    } else {      
      return <span>{seconds}</span>;
    }
  };

  function checkCanSend()
  {
    if(props.checkUser) {
      
      apiClient.post(`/api/check_user`,{
        country: form.country,
        phone: form.phone
      })
      .catch(function(err){
        setError(err.response.data.message)
      })
      .then((response) => {
        if(response.data.customer && response.data.customer == true) {        
          sendSMS();
        } else {
          setError('Please enter a valid phone number.')
        }
      })    

    } else {
      sendSMS();
    }
  }

  function sendSMS() {    

    setSmsLock(true)
    setError(false)

    apiClient.post(`/api/send_sms`,{
      country: form.country,
      phone: form.phone
    })
    .catch(function(err){
      setError(err.response.data.message)
    })
    .then((response) => {
      if(response) {        
        setSmsSent(response.data.to)        
      }
    })    

  }

  async function verifySMS() {
    setLoading(true)
    
    apiClient.post(`/api/verify_sms`,{
      code: form.code,
      to: smsSent
    })
    .catch(function(err){
      setError(err.response.data.message)
    })
    .then((response) => {
      
      if(response && response.data == 'approved') {
        
        setVerified(true)            

        if(props.setPhone) {
          props.setPhone(
            {...props.phone,
                country: form.country,
                number: form.phone
            })
        }
        props.setSmsVerified(true)  

      } else {
        setError('Invalid SMS code. Please try again.')
      }
    })
    .finally(() => {
      setLoading(false)
    }) 
    
  }

  return (
    <>
      <label className="font-bold">{t('your_phone')}</label>

      <div className="flex flex-col md:flex-row items-center gap-3 mt-1 mb-3">
        <ReactSelect
            styles={selectStyle}
            className="text-left w-full md:w-[150px]"
            options={countries_en}
            onChange={(e) => setForm({
                              ...form,
                              country: e.value
                            })}
            defaultValue={countries_en[0]}
        />
        <input type="number" value={form.phone} onChange={(e) => setForm({...form,phone: e.target.value})} placeholder={t('your_phone')} className="w-full md:w-auto"/>
        <button disabled={smsLock ? true : ""} onClick={() => checkCanSend() } className={classNames(smsLock ? "bg-gray-500":"bg-[#8B8444]","w-full md:w-auto text-sm font-bold opacity-90 hover:opacity-100 p-3 px-4 rounded-lg")}>
          {
            !smsLock ?
            t('send_sms')
            :
            <Countdown date={Date.now() + smsCountdown} renderer={countdown}/>
          }
        </button>
      </div>      
      {
        smsSent != false &&
        <>
          <label className="font-bold">{t('verify_sms_otp')}</label>
          <div className="flex mt-1 gap-3">                  
            <input type="number" value={form.code} onChange={(e) => setForm({...form,code: e.target.value})} placeholder={t('sms_code')} className="w-[120px]" />
            <button disabled={loading || verified ? true : ''} onClick={() => verifySMS() } 
              className={classNames(loading ? "bg-gray-500": (verified ? "" : "bg-[#8B8444] opacity-90 hover:opacity-100"),"text-sm font-bold p-3 px-4 rounded-lg")}>
              {
                loading ?
                <>
                  <Spinner />
                </>
                :
                <>
                  {
                    !verified ? t('verify_otp')                    
                    :
                    <div className="flex gap-1 text-green-500">
                      <CheckIcon className="w-5"/> {t('phone_verified')}
                    </div>
                  }
                </>
              }
            </button>
          </div>
        </>
      }
      {
        error &&
        <div className="text-red-500 mb-5">{error}</div>
      }
    </>
  );
}