 import { useState, useEffect } from 'react'
import { classNames, delay, Spinner } from '../../utils/helpers';
import dayjs from 'dayjs';
import {
  ChevronRightIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';
import { useNavigate } from "react-router-dom";
import Paypal from "./Paypal";
import WechatPay from "./WechatPay";
import Alipay from "./Alipay";
import { useTranslation } from 'react-i18next';
import ImportantNotes from "../ImportantNotes";
import { isMobile } from 'react-device-detect';
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function BookingStep4(props) {

  dayjs.extend(customParseFormat)
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [paying, setPaying] = useState(false);
  const [terms, showTerms] = useState(false);
  const [agree,setAgree] = useState(false);
  const navigate = useNavigate();
  const total_fee = props.bkinfo.price * props.bkinfo.slots;
  const [succeeded, setSucceeded] = useState(false)
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");

  useEffect(() => {
    if(succeeded === true) {
        props.setCompleted(true);
    }
  },[succeeded]);

  return (
      <>

        <h4 className="font-bold text-gold">{t('please_double_check')}</h4>

        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 my-5 pb-5 border-b border-gray-800">
          <div className="md:order-1">
            <strong className="text-xs block">{t('booking_code')}</strong>
            <span>{props.bkinfo.code}</span>
          </div>
          <div className="md:order-4">
            <strong className="text-xs block">{t('gender')}</strong>
            <span>{props.bkinfo.customer.gender == 'M' ? 'Male' : 'Female'}</span>
          </div>
          <div className="md:order-2">
            <strong className="text-xs block">{t('fname')}</strong>
            <span>{props.bkinfo.customer.first_name}</span>
          </div>
          <div className="md:order-3">
            <strong className="text-xs block">{t('lname')}</strong>
            <span>{props.bkinfo.customer.last_name}</span>
          </div>          
          <div className="md:order-5">
            <strong className="text-xs block">{t('phone')}</strong>
            <span>+{props.bkinfo.country} {props.bkinfo.phone}</span>            
          </div>
          <div className="md:order-6">
            <strong className="text-xs block">{t('email')}</strong>
            <span>{props.bkinfo.customer.email}</span>
          </div>
          <div className="md:order-7 md:col-span-3">
            <strong className="text-xs block">{t('special_requests')}</strong>
            <p>{props.bkinfo.notes}</p>
            <div className="flex gap-5 text-sm">
              <p><strong className="text-gold">{t('food_restrictions')}:</strong> {props.bkinfo.food}</p>
              <p><strong className="text-gold">{t('special_occasion')}:</strong> {props.bkinfo.occasion}</p>
            </div>

          </div>
        </div>

        <div className="flex flex-col md:flex-row border-b border-gray-800 pb-5 my-5">
          <div className="md:w-1/2 grid grid-cols-2 gap-3">
            <div>
              <strong className="text-xs block">{t('date_visit')}</strong>
              <span>{dayjs(props.bkinfo.booking_date).format('DD-MMM-YYYY, ddd')}</span>
            </div>
            <div className="text-center">
              <strong className="text-xs block">{t('time')}</strong>
              <span>{dayjs(props.bkinfo.time,'HHmm').format('HH:mm')}</span>
            </div>
            <div>
              <strong className="text-xs block">{t('persons_seats')}</strong>
              <span>{props.bkinfo.slots}</span>
            </div>
            <div className="text-center">
              <strong className="text-xs block">{t('payable_deposit')}</strong>
              <h4 className="text-amber-500 font-bold text-xl">RMB {total_fee}</h4>
            </div>
          </div>
          <div className={classNames(i18n.language == 'zh' ? "":"text-xs ","w-full mt-3 md:mt-0 md:w-1/2 p-1 px-2 border border-red-500 rounded")}>
            <strong className="block font-bold text-red-500">{t('deposit_policy')}</strong>
            {
              props.bkinfo.country == 86 ?
              <>
                {t('deposit_policy_content_local')}
              </>
              :
              <>
                {t('deposit_policy_content_foreign')}
              </>
            }
          </div>
        </div>

        {
          !succeeded &&
          <label className="flex gap-x-3 items-start border-b border-gray-800 pb-5 my-5">
            <input className="w-5 h-5" onChange={(e) => setAgree(!agree)} type="checkbox" value={agree} /> <span className="leading-tight">
            {t('read_terms1')} <strong onClick={() => showTerms(true) } className="text-gold">{t('terms_conditions')}</strong> {t('read_terms2')}</span>
          </label>
        }

        {
          paymentErrorMessage &&
          <p className="p-4 text-red-500 border border-red-500 rounded-lg">
            {paymentErrorMessage}
          </p>
        }
        {
          succeeded &&
          <p className="p-4 text-green-500 border border-green-500 rounded-lg">
            Congratulations, your payment and booking is successful. Please check your email for the confirmation email from us. If you do not receive a SMS or confirmation email, please contact us at 17301605350.<br/>
            您的预订已完成！若您未收到邮件和短信确认 请联系我们 17301605350
          </p>
        }
        {
          agree && !succeeded &&
          <>
            <h4 className="font-bold text-amber-500 mb-3 text-center">{t('pay_deposit_with')}</h4>

            <div className="flex flex-col sm:flex-row justify-center items-center gap-5">
              {
                loading ?
                <>
                  <div className="p-5 border border-gray-700 flex gap-3 rounded-lg">
                    <Spinner/> <span>{t('processing_payment')}</span>
                  </div>
                </>
                :
                <>
                  {/*
                  <div className="w-full sm:w-1/3">
                    <Alipay 
                      setSucceeded={setSucceeded} 
                      bkinfo={props.bkinfo}
                      paying={paying}
                      setPaying={setPaying}
                      isMobile={isMobile}
                    />
                  </div>                  
                */}
                  <div className="w-full sm:w-1/3">
                    <WechatPay
                      setSucceeded={setSucceeded} 
                      bkinfo={props.bkinfo}
                      paying={paying}
                      setPaying={setPaying}
                      isMobile={isMobile}
                    />
                  </div>    

                  <div className="w-full sm:w-1/3">
                    <Paypal 
                      setSucceeded={setSucceeded} 
                      bkinfo={props.bkinfo}
                      paying={paying}
                      setPaying={setPaying}
                    />
                  </div>
                  
                </>
              }          
            </div>
          </>
        }        

        {
          terms &&
          <div className="fixed z-[999] top-0 left-0 w-full h-full bg-black/80 backdrop-blur flex justify-center items-center">
            <div className="relative bg-base w-1/2 rounded-xl overflow-hidden">
              <button onClick={() => showTerms(false)} className="absolute top-2 right-2"><XMarkIcon className="w-8 text-white hover:text-red-500"/></button>
              <div className="h-[calc(80vh)] py-5 p-8 overflow-y-auto scrollable">                
                <div className="terms">
                  <ImportantNotes/>
                </div>
              </div>
            </div>
          </div>
        }
        

      </>

  );
}