import './index.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { PAYPAL_CLIENT_ID, apiClient } from "./utils/helpers.js"
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'
import Nav from './components/Nav';

import Landing from './routes/Landing';
import Home from './routes/Home';
import Booking from './routes/Booking';
import BookingProcess from './routes/BookingProcess';
import BookingResult from './routes/BookingResult';
import MyBookings from './routes/MyBookings';
import Faq from './routes/Faq';
import Contact from './routes/Contact';

import { getSiteStatus } from './utils/helpers';

function App() {

  const { t, i18n } = useTranslation();  

  const [siteState,setSiteState] = useState();

  useEffect(() => {
    const lang = localStorage.getItem('tt_lang');
    if(lang && i18n.language != lang) {
      i18n.changeLanguage(lang);
    }

    apiClient.get(`/api/get_status`).then((res) => {
      setSiteState(res.data);
    });

  },[]);

  const state = getSiteStatus();


  return (
    <div className="App text-white">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Welcome to Taian Table Shanghai</title>
          <link rel="canonical" href="https://bookings.taian-table.com" />
      </Helmet>
      <BrowserRouter>
        {
          siteState == 'live' &&
          <Nav/>
        }
        <div className="mx-auto max-w-screen-xl px-3 md:px-8">
          <PayPalScriptProvider options= {{"client-id": PAYPAL_CLIENT_ID}}>
            <Routes>
              <Route path="/" element={<Landing/>} />
              <Route path="/home" element={<Home/>} />
              <Route path="/booking" element={<Booking/>} />
              <Route path="/booking_process/:id" element={<BookingProcess/>} />
              <Route path="/booking_result" element={<BookingResult/>} />
              <Route path="/my_bookings" element={<MyBookings/>} />
              <Route path="/faq" element={<Faq/>} />
              <Route path="/contact" element={<Contact/>} />
              <Route path="*" element={<Navigate to="/"/>} />
            </Routes>
          </PayPalScriptProvider>
        </div>
        <div className="text-xs py-3 text-center">
          &copy; 上海亦村餐饮管理 / 沪ICP备2022031586号-1
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
