import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { selectStyle, classNames, delay, Spinner, apiClient } from '../utils/helpers';
import ReactSelect from 'react-select';
import Countdown from 'react-countdown';
import {
  InformationCircleIcon
} from '@heroicons/react/20/solid';
import VerifyPhone from '../components/VerifyPhone'
import MyBookingItem from '../components/MyBookings/MyBookingItem'
import { useTranslation } from 'react-i18next';

export default function MyBookings(props) {

  const { t, i18n } = useTranslation();
  const [loading,setLoading] = useState();
  const [smsVerified, setSmsVerified] = useState(false);
  const [bookings,setBookings] = useState();
  const [error,setError] = useState();
  const [phone,setPhone] = useState();
  const [customer,setCustomer] = useState();

  useEffect(() => {
    if(smsVerified == true) {      
      loadUserBookings()
    }
  },[smsVerified]);  

  async function loadUserBookings() {  

    setLoading(true) 

    apiClient.post(`/api/get_user_bookings`,{
      country: phone.country,
      phone: phone.number
    })
    .catch(function(err){
      setError(err.response.data.message)
    })
    .then((response) => {
      
      setCustomer(response.data.customer);
      setBookings(response.data.bookings);

    })
    .finally(() => {
      setLoading(false)
    })
    
  } 
  
  return (
    <div className="pt-12">

      <div className="flex flex-col max-w-screen-md mx-auto my-8">

        <div className="p-5 px-8 bg-base border border-gray-800 rounded-xl relative">          
          {
            !smsVerified &&
            <div>
              <h3 className="font-bold text-gold">{t('login_to_see_bookings')}</h3>
              <VerifyPhone setPhone={setPhone} checkUser={true} setSmsVerified={setSmsVerified}/>
            </div>          
          }
          {
            smsVerified && customer &&
            <div>
              <h3 className="font-bold text-gold text-xl">{t('welcome_back',{fname:customer.first_name,lname:customer.last_name,gender: customer.gender == 'M' ? t('mr') : t('ms')})}</h3>
              <p>
                {t('find_your_bookings_below')}
              </p>
              {
                loading &&
                <>
                  <div className="flex gap-x-3 text-red-500 my-3">
                    <Spinner />  Loading Your Bookings...
                  </div>
                </>
              }
              {
                bookings &&
                <div className="flex flex-col gap-3 divide-y divide-gray-800 my-5 border-t border-gray-800">
                {
                  bookings.map((b,i) => (
                    <MyBookingItem booking={b} key={i}/>                  
                  ))
                }
                </div>
              }              
            </div>
          }
          

        </div>        

      </div>

    </div>
  );
}