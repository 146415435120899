import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    
    // translation strings
    resources: {
      en: {
        translation: {
         home_welcome: "Welcome to Taian Table Online Reservation",
         home_help: "How may we help you today?",
         book_a_table: "Book a Table",
         check_my_booking: "Check my Booking",
         where_to_find_us: "Where to Find Us?",
         new_booking: "New Booking",
         check_your_bookings: "Check Your Bookings",
         booking: "Booking",
         nav_about:"About Taian Table",
         nav_faq:"FAQ",
         nav_contact:"Contact Us",
         choose_date:"Please choose the date that you would like to visit.",
         booking_pane1:"Please choose the number of persons for the time you wish to visit.",
         booking_pane2:"Kindly contact us if you wish to make a booking for more than {{max_pax}} persons.",
         full:"FULL",
         closed:"CLOSED",
         book_again:"Book Again",

         your_phone:"Your Phone Number",
         send_sms:"Send SMS OTP",
         verify_sms_otp:"Verify SMS OTP",
         sms_code:"SMS Code",
         verify_otp:"Verify OTP",
         verify_success:"Phone Verified!",

         booking_process_persons_at:"{{pax}} Persons at {{time}}",
         complete_booking_limit:"Please complete this booking within {{mins}} minutes.",
         booking_error:"Please contact us with details of your booking if you continue to experience issues.",
         booking_expired:"Sorry, your booking has expired as you have taken more than {{mins}} minutes to complete your booking.",
         
         booking_step1:"Please Confirm Your Phone Number",
         booking_step2:"Please Confirm Your Details",
         booking_step3:"Please Let us Know Your Requests",
         booking_step4:"Please Confirm Your Booking Details",

         important_notes:"Important Notes",

         dining_notes:"Dining Notes",
         dining_notes1:"Our restaurant operates every Tuesday to Saturday.",
         dining_notes2:"We are offering three optional dining times: 6 p.m - 8 p.m.",
         dining_notes3:"To ensure the best dining experience, we will only keep your seats for 30 minutes, please coordinate in advance and arrive on time.",
         dining_notes4:"The whole dining experience will last about 2.5 hours",
         dining_notes5:"Our restaurant operates from 6 p.m.. Please kindly note that if you arrive early, you may only be able to wait outside.",
         dining_notes6:"The dining area is non-smoking (including E-cigarette), but we have outdoor smoking area.",

         seats_arrangement:"Seats Arrangement",
         seats_arrangement1:"Your seat will be arranged by us according to the on-day booking status. Seats will be mainly arranged at the counter. Booth seats will be arranged priority to guests who have reserved for 3-4 persons.",
         seats_arrangement2:"If you have the reservation of two persons and request for the booth seat, we will try our best for the arrangement according to the booking status.",

         rules_of_byo:"Rules of BYO",
         rules_of_byo1:"If you are bringing your own wine, please kindly note that we only allow bringing a maximum of one bottle (up to 750ml) per party. Taian Table will charge a fee of RMB 800 per bottle for the handling, glassware, and related service.",

         reminder_menu:"Reminders of Menu & Vegetarian & Child Policy",
         reminder_menu1:"If you have any food allergy or restrictions, please let us know at least 48 hours before the dining date, and we will adjust the ingredients accordingly. Due to the limits of preparation, we are not able to provide unreserved vegetarian menus on-day.",
         reminder_menu2:"Children less than 12 years of age are not recommended for dining. If you are coming with children, please take note that your children are part of the booked seats, even if they are not eating.",

         cancellation_policy:"Modification and Cancellation Policy",
         cancellation_policy1:"You can cancel your reservation at least 4 working days (Monday to Friday) prior to your booking with no charge. In these cases, we can either refund your deposit or, alternatively, provide a possible date for your future dining.",
         cancellation_policy2:"If you cancel your reservation within 4 working days (Monday to Friday) prior to your booking, your deposit will not be refunded. But you can still re-arrange your reservation for a future dining.",
         cancellation_policy3:"Kindly note that due to the limited number seats availability at Taian Table, any modification requests to reduce the number of seats in a booking will be handled as a cancellation of seats. And any modification requests to add the number of seats are subjected to the on-day booking.",
         cancellation_policy4:"If you paid your deposit through Paypal, we need to charge 3.5% of the total amount for administration and service fee when we process the refund to your account.",
         cancellation_policy5:"Please understand that we have taken great consideration in our booking and cancellation policy. We source fresh ingredients daily based on the number of bookings to ensure the quality and reduce wastage.",
         cancellation_policy6:null,

         welcome_back:"Welome back, {{gender}} {{fname}} {{lname}}",
         welcome:"Welcome to Taian Table",
         please_check:"Please check if your information below is correct.",
         please_fill:"Please fill in your personal details to proceed with your booking.",
         mr:'Mr. ',
         ms:'Ms. ',
         fname:"First Name",
         lname:"Last Name",
         gender:"Gender",
         male:"Male",
         female:"Female",
         phone:"Phone Number",
         email:"Email Address",
         confirm:"Confirm",
         next:'Next',

         thank_you_guest:"Thank you {{gender}} {{fname}} {{lname}}",
         any_special_requests:"Please let us know if you have any special requests for this visit.",
         food_restrictions:"Food Restrictions",
         other_food:"Other Food Restriction",
         special_occasion:"Special Occasion",
         other_occasion:"Other Occasion",
         proceed_payment:"Proceed to Payment",

         please_double_check:"Please double check your booking details before proceeding with deposit payment.",
         booking_code:"Booking Code",
         special_requests:"Special Requests",
         date_visit:"Date of Visit",
         time:"Time",
         persons_seats:"Persons/Seats",
         payable_deposit:"Payable Deposit",
         deposit_policy:"Deposit Policy",
         deposit_policy_content_local:"Paid deposits will be deducted from your final dining bill at the restaurant.",
         deposit_policy_content_foreign:"Paid deposits will be deducted from your final dining bill at the restaurant.",
         read_terms1:"I have read and understood the",
         terms_conditions:"terms and conditions",
         read_terms2:"of this booking.",
         pay_with:"Pay {{fee}} with:",
         pay_deposit_with:"Pay deposit with:",
         processing_payment:"Processing Payment",
         please_scan_code:"Please scan the QR Code with Wechat App to make payment. This QR code does not support wechat scanning from the same phone. Please use a different phone to scan the QR code, or open the booking page on another device, and scan the QR code with your phone. Sorry for any inconvenience caused.",
         encounter_problems:"If you encounter any problems with paying, please contact us on wechat or give us a call.",

         login_to_see_bookings:"Please Login to See Your Bookings",
         find_your_bookings_below:"Please find your bookings below.",
         upcoming_booking:"Upcoming Booking",
         completed:"Completed",
         cancelled:"Cancelled",
         show_details:"Show Details",
         persons:"Persons",
         change_booking:"Change Booking",
         cancel_booking:"Cancel Booking",
         booking_placed_on:"Booking Placed On",
         deposit_method:"Deposit Payment Method",
         deposit_amount:"Deposit Amount",
         send_request:"Submit Request",
         change:"Change ",
         cancel:"Cancel ",
         close:"Close",
         booking_reason:"Booking Reason/Details",
         thankyou_for_request:"Thank you, your request has been submitted. We will reach out to your ASAP to confirm with you your request.",

         thankyou_for_enquiry:"Thank you, your enquiry has been sent. We will get back to you ASAP.",
         contact_us_title:"Contact Us / Where to Find Us",
         click_to_enlarge:"Click to enlarge",
         to_email_us:"To email us, please use the contact form on the right.",
         address:"Address",
         ttsh_address:"101-102, Building No. 1, Garden Office, No.161, Lane 465, Zhenning Road, Shanghai, China",
         your_name:"Your Name",
         your_email:"Your Email",
         your_message:"Your Message",
         what_can_we_help:"What can we help you with?",
         send_enquiry:"Send Enquiry",

         faq_heading:"FAQ - Frequently Asked Questions",
         faq_title:"Welcome to Taian Table",
         faq_intro:"Taian Table is an intimate dining venue offering a multi-course tasting menu. An evening at Taian Table is much like attending a private dinner party at a friend’s home – that is, if your friend happened to be a fine-dining chef!",
         faq_intro2:"Please find some of our frequently asked questions below.",
        }
      },
      zh: {
        translation: {
         home_welcome: "欢迎访问泰安门线上预订平台",
         home_help: "请选择您的服务需求",
         book_a_table: "餐位预订",
         check_my_booking: "预订查询",
         where_to_find_us: "联系方式及餐厅地址",
         new_booking: "新预订",
         check_your_bookings: "预订查询",
         booking: "餐位预订",
         nav_about:"关于泰安门",
         nav_faq:"FAQ",
         nav_contact:"联系方式",
         choose_date:"请选择您所期望的抵店日期",
         booking_pane1:"请选择当日就餐人数",
         booking_pane2:"如您预期的就餐人数多于{{max_pax}}位，敬请联系餐厅咨询详情。",
         full:"已满坐",
         closed:"不营业",
         book_again:"重新预订",

         your_phone:"您的手机号",
         send_sms:"发送SMS验证码",
         verify_sms_otp:"验证短信验证码",
         sms_code:"短信验证码",
         verify_otp:"验证",
         verify_success:"验证成功!",

         booking_process_persons_at:"{{pax}}位, 用餐时间: {{time}}",
         complete_booking_limit:"敬请您在{{mins}}分钟内完成预订流程",
         booking_error:"如果您收到此报错信息，请与我们联系，并且提供您的预订信息。",
         booking_expired:"抱歉, 您的预订过程已超过 {{mins}} 分钟，因此已失效。",
         
         booking_step1:"请确认您的手机号",
         booking_step2:"请确认您的个人信息",
         booking_step3:"请告知任何特殊要求",
         booking_step4:"请确认您的预订信息",

         important_notes:"温馨提示",

         dining_notes:"到店用餐提示",
         dining_notes1:"餐饮营业时间为每周二至周六",
         dining_notes2:"我们现提供三个自选入席时间：18:00至20:00",
         dining_notes3:"因本店地理位置较为隐蔽，请您务必提前预留充足的路程时间，准时入席。为确保最佳用餐体验，我们仅将为您保留30分钟的席位安排。",
         dining_notes4:"整餐体验预计耗时2.5小时，我们将按您的预订人数备餐。若有人数变动，请务必于中午12点前告知我们；若过时告知，缺席的宾客将按人数被扣除相应定金以作备餐费用",
         dining_notes5:"餐厅的营业时间自晚间6点起，我们暂未设立室内等候区域，请勿提前至店。",
         dining_notes6:"餐厅用餐区域禁止抽烟（包含电子烟），但我们在室外设置了吸烟区。",

         seats_arrangement:"座位安排标准",
         seats_arrangement1:"您的座位将由我们根据当日预订情况进行安排，以板前吧台座位为主，2张卡桌将优先安排给预订3-4位的宾客入座。",
         seats_arrangement2:"若用餐宾客为2位，并有优先入座卡桌的需求，在没有3-4位宾客预订的前提下，我们也会尽最大可能帮您安排。",

         rules_of_byo:"自带酒水服务费规则",
         rules_of_byo1:"就餐期间，每组用餐食客限自带一瓶标准量（至多750 ml）的葡萄酒。我们提供开瓶、适用酒杯以及其他相关服并将收取人民币800元每瓶的服务费用。",

         reminder_menu:"菜单&素食&儿童政策提示",
         reminder_menu1:"若您对菜品及食材有任何过敏反应或忌口，敬请您于就餐前至少48小时告知，我们会提前准备相应的菜品调整。因食材采购的时间限制，恕我们无法当日提供未预约的素食菜单。",
         reminder_menu2:"我们恕不接待12岁以下的儿童，若您需要携带儿童用餐，我们将按照成人菜单进行收费。",

         cancellation_policy:"预订取消与修改规则",
         cancellation_policy1:"在您的预订日期前4个工作日(周一至周五)，您可以免责取消或改期您的预定。在此条件下，我们可以全额退还您的定金。",
         cancellation_policy2:"如您的预订在4个工作日内取消，恕我们不能退还订金，但是，您还可以选择改期。",
         cancellation_policy3:"请注意，由于泰安门席位有限，任何预订改期或人数增加必须是在仍有空位的情况下。如您需要减少预订人数则视为取消此座位。",
         cancellation_policy4:"如您是通过贝宝支付订金的，在退还订金时我们需要收取3.5% 手续费和服务费",
         cancellation_policy5:null,
         cancellation_policy6:null,

         welcome_back:"Welome back, {{fname}} {{lname}} {{gender}} ",
         welcome:"欢迎到泰安门",
         please_check:"请确认您的个人信息是否正确",
         please_fill:"请填写您的个人信息进行预订。",
         mr:'先生',
         ms:'女士',
         fname:"名字",
         lname:"姓",
         gender:"性别",
         male:"男",
         female:"女",
         phone:"手机号",
         email:"电子邮箱",
         confirm:"确认",
         next:'下一步',

         thank_you_guest:"感谢您 {{fname}} {{lname}} {{gender}} ",
         any_special_requests:"请问本次用餐有什么特别需求吗？",
         food_restrictions:"食物需求",
         other_food:"其他食物需求",
         special_occasion:"特别节日",
         other_occasion:"其他节日",
         proceed_payment:"前往支付预订",

         please_double_check:"请再次确认以下预订信息。",
         booking_code:"预订码",
         special_requests:"特别需求",
         date_visit:"用餐日期",
         time:"时间",
         persons_seats:"人数",
         payable_deposit:"支付定金",
         deposit_policy:"定金条款",
         deposit_policy_content_local:"您支付的订金会在您当天买单时扣处。",
         deposit_policy_content_foreign:"您支付的订金会在您当天买单时扣处。",
         read_terms1:"我已阅读及了解",
         terms_conditions:"服务条款和条件。",
         read_terms2:"",
         pay_with:"请使用以下支付方式支付 {{fee}} 定金",
         pay_deposit_with:"请使用以下付款方式支付定金",
         processing_payment:"正在进行支付...",
         please_scan_code:"请使用微信APP扫描二维码进行微信支付。本二维码不支持本机图片识别， 请使用第二手机扫码支付或者电脑打开小程序用手机扫码支付。造成不便尽请谅解。",
         encounter_problems:"如果您遇到支付问题, 请通过微信或电话与我们联系。",

         login_to_see_bookings:"请使用手机登录查询您的预订信息",
         find_your_bookings_below:"请在下方查看您的预订记录。",
         upcoming_booking:"未来预订",
         completed:"已完成",
         cancelled:"已取消",
         show_details:"查看详情",
         persons:"位",
         change_booking:"申请预订变更",
         cancel_booking:"申请取消预订",
         booking_placed_on:"下单日期",
         deposit_method:"定金支付方式",
         deposit_amount:"定金金额",         
         send_request:"提交申请",
         change:"更改",
         cancel:"取消",
         close:"关闭",
         booking_reason:"预订理由以及详情",
         thankyou_for_request:"感谢您，您的申请已提交。我们会尽快与您联系。",

         thankyou_for_enquiry:"感谢您的来电，我们会尽快与您联系。",
         contact_us_title:"联系我们 / 我们的位置",
         click_to_enlarge:"点击放大地图",
         to_email_us:"如您想要经电邮联系我们，请将您的需求详情填写至右侧信息栏。",
         address:"地址",
         ttsh_address:"上海市长宁区镇宁路465弄161号1号楼101-102室",
         your_name:"您的姓名",
         your_email:"您的电邮地址",
         your_message:"您的需求详情",
         what_can_we_help:"请问我们能如何帮助您？",
         send_enquiry:"提交信息",

         faq_heading:"FAQ - 常问问题",
         faq_title:"欢迎到泰安门",
         faq_intro:"泰安门是一家提供独特精致美食的私人晚宴会所。在享用晚宴时您会感觉像在朋友家开私人派对一样（假如碰巧您的朋友又刚好是一位手艺不错的大厨），大家一起围坐在大厨料理台旁畅所欲言。而且泰安门餐厅的厨房是完全开放式的，您还可以和主厨们互动聊天。料理台可容纳至多20名食客，餐厅内还另外设有两个4人位用餐席位桌及容乃12位食客的私人包房。",
         faq_intro2:"请参考以下常问问题",
        }
      },
    }
  });

export default i18n;