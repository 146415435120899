import { useState } from 'react'
import { classNames, apiClient } from '../../utils/helpers';
import { PayPalButtons } from "@paypal/react-paypal-js";

export default function Paypal(props) {

  const total_fee = props.bkinfo.price * props.bkinfo.slots;

  /* Payment states */    
  const [orderID, setOrderID] = useState(false);
  const [billingDetails, setBillingDetails] = useState("");

  const createOrder = (data, actions) => {    
    props.setPaying(true)
    return apiClient.post(`/api/paypal_create`,{
      'code' : props.bkinfo.code
    })
    .catch(function(err){
      console.log(err)
    })
    .then((response) => {   
      return response.data.id
    })    
  }

  const onApprove = (data, actions) => {    
    return apiClient.post('/api/paypal_capture' , {          
      'oid' : data.orderID,
    })
    .catch(function(err){
      console.log(err)
    })
    .then(function(response) {
      if(response.data === 'COMPLETED') {
        props.setSucceeded(true)
      } else {
        props.setPaymentErrorMessage("Something went wrong while confirming your Paypal payment. Please contact us with your booking code.");
      }
      props.setPaying(false)
    });    
  };

  const onError = (data,actions)=>{
     props.setPaymentErrorMessage("Something went wrong with your PayPal payment. Please try again. If this persists, please contact us with your booking code.");
     props.setPaying(true)
  }

  const cancelOrder = () => {
    props.setPaying(false)
  }

  return (
      <>
        <PayPalButtons
          disabled={props.paying ? true : false}
          style={{
            color: "gold",
            height: 55,
            shape: 'pill',
            label: "pay",
            tagline: false,
            layout: "horizontal",
          }}
          createOrder={createOrder}
          onApprove={onApprove}
          onCancel={cancelOrder}
        />
      </>

  );
}