import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function Home(props) {

  const { t, i18n } = useTranslation();

  const btnClass = "p-2 px-4 border-2 rounded-lg text-white hover:border-red-500 hover:text-red-500"
  
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="w-[300px]">
        <img className="" src="/img/logo.png" />
      </div>
      <div className="text-center my-8">
        <p>
          {t('home_welcome')}
        </p>
        <p>
          {t('home_help')}
        </p>
      </div>      
      <div className="flex flex-col gap-3 text-center">
        <Link to="/booking" className={btnClass}>{t('book_a_table')}</Link>
        <Link to="/my_bookings" className={btnClass}>{t('check_my_booking')}</Link>
        <Link to="/contact" className={btnClass}>{t('where_to_find_us')}</Link>
      </div>
    </div>
  );
}