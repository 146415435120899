import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { apiClient } from '../utils/helpers';
import { useEffect, useState } from 'react'

export default function Landing(props) {

  const { t, i18n } = useTranslation();
  const [siteState,setSiteState] = useState();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  useEffect(() => {
    apiClient.get(`/api/get_status`).then((res) => {
      setSiteState(res.data);
    });
  },[]);

  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="w-[300px]">
        <img className="" src="/img/logo.png" />
      </div>

      <p className="mt-5">
        Welcome to Taian Table Online Reservation 欢迎访问泰安门线上预订平台
      </p>
      
      <div className="text-center my-3 mb-5">
      {
        siteState == 'closed' &&
        <>          
          <p>
            Online reservation is currently closed. 线上预订已暂停。
          </p>
          <p>
            Please check back again later. 请稍后再访问。
          </p>
        </>
      }

      {
        siteState == 'maintenance' &&
        <>
          
          <p>
            We are currently undergoing system maintenance. 我们正在进行系统维护。
          </p>
          <p>
            Please check back again later. 请稍后再访问。
          </p>
        </>
      }
        
      {
        siteState == 'live' &&
        <p>
          Please choose your language 请选择您的语言
        </p>
      }
        
      </div>
      {
        siteState == 'live' &&
        <div className="flex gap-3">
          <Link onClick={() => handleTrans('en')} to="/home" className="p-2 px-4 border-2 rounded-lg text-white hover:border-red-500 hover:text-red-500">
            English
          </Link>
          <Link onClick={() => handleTrans('zh')} to="/home" className="p-2 px-4 border-2 rounded-lg text-white hover:border-red-500 hover:text-red-500">
            中文
          </Link>
        </div>
      }
    </div>
  );
}