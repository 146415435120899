import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function ImportantNotes(props) {

  const { t, i18n } = useTranslation();
  
  return (
    <>
      <h3 className="font-bold uppercase text-red-500 mb-1">{t('important_notes')}</h3>

      <div className="flex flex-col gap-3 p-3 px-5 border border-gold/50 rounded">
        <div className="mb-3">
          <h3 className="text-sm font-bold uppercase text-gold">{t('dining_notes')}</h3>
          <ol className="list-decimal ml-4">
            <li>{t('dining_notes1')}</li>              
            <li>{t('dining_notes2')}</li>
            <li>{t('dining_notes3')}</li>
            <li>{t('dining_notes4')}</li>
            <li>{t('dining_notes5')}</li>
            <li>{t('dining_notes6')}</li>
          </ol>
        </div>

        <div className="mb-3">
          <h3 className="text-sm font-bold uppercase text-gold">{t('cancellation_policy')}</h3>
          <ol className="list-decimal ml-4">
            <li>{t('cancellation_policy1')}</li>
            <li>{t('cancellation_policy2')}</li>
            <li>{t('cancellation_policy3')}</li>
            <li>{t('cancellation_policy4')}</li>
            { t('cancellation_policy5') && <li>{t('cancellation_policy5')}</li>}
            { t('cancellation_policy6') && <li>{t('cancellation_policy6')}</li>}
          </ol>
        </div>

        <div className="mb-3">
          <h3 className="text-sm font-bold uppercase text-gold">{t('seats_arrangement')}</h3>
          <ol className="list-decimal ml-4">
            <li>{t('seats_arrangement1')}</li>
            <li>{t('seats_arrangement2')}</li>
          </ol>
        </div>

        <div className="mb-3">
          <h3 className="text-sm font-bold uppercase text-gold">{t('rules_of_byo')}</h3>
          <ol className="list-decimal ml-4">
            <li>{t('rules_of_byo1')}</li>
          </ol>
        </div>

        <div className="mb-3">
          <h3 className="text-sm font-bold uppercase text-gold">{t('reminder_menu')}</h3>
          <ol className="list-decimal ml-4">
            <li>{t('reminder_menu1')}</li>
            <li>{t('reminder_menu2')}</li>
          </ol>
        </div>
        
      </div>
    </>
  );
}