import { useState, useEffect } from 'react'
import { useParams, Link } from "react-router-dom";
import dayjs from 'dayjs';
import { selectStyle, classNames, Spinner, apiClient } from '../utils/helpers';
import ReactSelect from 'react-select';
import Countdown from 'react-countdown';
import {
  InformationCircleIcon
} from '@heroicons/react/20/solid';
import StepsIndicator from '../components/Booking/StepsIndicator';
import BookingStep1 from '../components/Booking/BookingStep1';
import BookingStep2 from '../components/Booking/BookingStep2';
import BookingStep3 from '../components/Booking/BookingStep3';
import BookingStep4 from '../components/Booking/BookingStep4';
import ImportantNotes from '../components/ImportantNotes';
import { useTranslation } from 'react-i18next';
import customParseFormat from "dayjs/plugin/customParseFormat";

export default function BookingProcess(props) {  

  dayjs.extend(customParseFormat)
  const { t, i18n } = useTranslation();
  const [count,setCount] = useState(Date.now())
  const [timelimit,setTimeLimit] = useState(30*60);
  const [timesup,setTimesup] = useState(false);
  const [error,setError] = useState(false);
  const [loading,setLoading] = useState(false);
  const [completed,setCompleted] = useState(false);
  const [bkinfo, setBkinfo] = useState({});
  const [step,setStep] = useState(1);  
  
  let { id } = useParams();

  useEffect(() => {
    // :: INITIALISATION :: 
    fetchBooking()
  },[]);  

  useEffect(() => {       
    setStep(bkinfo.last_step+1)  
    if(bkinfo.last_step > 0) {
      update_booking()
    }        
  },[bkinfo.last_step])

  const fetchBooking = () => {
    try {
      apiClient.get(`/api/fetch_booking/${id}`)
        .then((response) => {
          if(response.data != '404') {            
            // Check if booking has expired.
            let createdAt = dayjs(response.data.created_at)
            let now = dayjs()
            let timeDiff = now.diff(createdAt,'minute')            
            if(timeDiff > response.data.booking_time_limit) {
              setTimesup(true)
              setTimeLimit(0)
            } else {
              setTimeLimit( (response.data.booking_time_limit*60) - now.diff(createdAt,'second'))
            }

            setBkinfo({ 
              code: response.data.code , 
              customer_id: response.data.customer_id,
              booking_date: response.data.booking_date , 
              slots: response.data.pax, 
              time: response.data.seating.timeslot.name,
              country: response.data.country,
              phone: response.data.phone,
              notes: response.data.notes,
              food: response.data.food,
              occasion: response.data.occasion,
              last_step: response.data.last_step,
              price: response.data.price,
              status_id: response.data.status_id,
              booking_time_limit: response.data.booking_time_limit
            })
            

            // If customer data exists, populate in bkinfo.
            if(response.data.customer) {
              setBkinfo(prevState => {
                return {...prevState,customer:response.data.customer}
              })
            }
          } else {            
            setError('Invalid Booking Code')
          }        
        }) 
      setError(null)
    } catch(err) {
      setError(err.message)
    } finally {      
      setLoading(false)
    }
  }

  const update_booking = () => {
    // Save partial booking info on server side.
    apiClient.post(`/api/update_booking`,{
      code: bkinfo.code,
      country: bkinfo.country,
      phone: bkinfo.phone,      
      last_step: bkinfo.last_step,
      customer_id: bkinfo.customer_id,
      notes: bkinfo.notes ? bkinfo.notes : '',
      food: bkinfo.food ? bkinfo.food : '',
      occasion: bkinfo.occasion ? bkinfo.occasion : '',
    })
    .catch(function(err){
      setError(err.response.data.message)
    })
    .then((response) => {
      if(response.data == 200) {        
        // console.log('booking saved')
      } else {
        console.log('error')
      }           
    })    
  }


  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {      
      setTimesup(true)
    } else {      
      return <span>{minutes}:{`${seconds < 10 ? seconds : seconds}`}</span>;
    }
  };

  return (
    <div className="pt-[80px] md:pt-12">

      <div className="flex flex-col max-w-screen-md mx-auto md:my-8">

        <StepsIndicator step={step} completed={completed}/>
        
        <div className="p-5 px-8 bg-base border border-gray-800 rounded-xl relative">          

          <div className="absolute top-2 right-3 flex gap-1">
            {
              bkinfo.code && !completed &&
              <Countdown date={count + 1000*timelimit } renderer={renderer}/>                        
            }
          </div>
          
          {
            !completed &&
            <>
              <h2 className="text-lg font-bold text-gold">{dayjs(bkinfo.booking_date).format('D MMMM YYYY, dddd')}</h2>
              <h1 className="font-bold text-2xl">{t('booking_process_persons_at',{pax:bkinfo.slots,time:dayjs(bkinfo.time,'HHmm').format('HH:mm')})}</h1>
            </>
          }          
          {
            !completed &&
            <p className="my-3">
              {t('complete_booking_limit',{mins:bkinfo.booking_time_limit})}
            </p>
          }          

          <div className="py-5 mt-5 border-t border-gray-800">
            {
              error &&
              <>
                <p className="text-red-700 p-3 border border-red-700 rounded-lg inline-block">
                  {error}
                </p>
                <p className="my-3 text-red-600">{t('booking_error')}</p>
                <Link className="inline-block bg-[#8B8444] opacity-90 hover:opacity-100 text-sm font-bold mt-3 p-3 px-4 rounded-lg" to="/booking">{t('book_again')}</Link>
              </>
            }
            {
              !timesup ?
              <>
                {
                  step === 1 &&
                  <BookingStep1 bkinfo={bkinfo} setBkinfo={setBkinfo}/>  
                }

                {
                  step === 2 &&
                  <BookingStep2 bkinfo={bkinfo} setBkinfo={setBkinfo}/>  
                }

                {
                  step === 3 &&
                  <BookingStep3 bkinfo={bkinfo} setBkinfo={setBkinfo}/>  
                }

                {
                  step === 4 &&
                  <BookingStep4 bkinfo={bkinfo} setBkinfo={setBkinfo} setStep={setStep} setCompleted={setCompleted}/>  
                }            
              </>
              :
              <>
                <p className="text-red-500">
                  {t('booking_expired',{mins:bkinfo.booking_time_limit})}
                </p>
                <Link className="inline-block bg-[#8B8444] opacity-90 hover:opacity-100 text-sm font-bold mt-3 p-3 px-4 rounded-lg" to="/booking">{t('book_again')}</Link>
              </>
            }            
            
          </div>

          <ImportantNotes/>

        </div>        

      </div>

    </div>
  );
}