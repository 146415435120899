import { useState, useEffect } from 'react'
import { classNames, delay, Spinner, apiClient } from '../../utils/helpers';
import {
  CheckIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid';
import VerifyPhone from '../VerifyPhone'

export default function BookingStep1(props) {

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smsVerified, setSmsVerified] = useState(false);
  const [phone,setPhone] = useState({
    country: '',
    number: ''
  });

  useEffect(() => {
    if(smsVerified == true) {
      props.setBkinfo({...props.bkinfo,country:phone.country, phone:phone.number, last_step: 1})
    }
  },[smsVerified]);  

  return (
      <>
        <VerifyPhone phone={phone} setPhone={setPhone} setSmsVerified={setSmsVerified}/>
        {
          error &&
          <div className="text-red-500 mb-5">{error}</div>
        }
      </>

  );
}