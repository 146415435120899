import { useState } from 'react'
import { Link } from "react-router-dom";
import { Spinner, apiClient } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

export default function Contact(props) {

  const { t, i18n } = useTranslation();
  const [enlarge,setEnlarge] = useState()
  const [form,setForm] = useState({
    name:'',
    email:'',
    message:''
  })
  const [error,setError] = useState(false);
  const [loading,setLoading] = useState(false);
  const [success,setSuccess] = useState(false);

  async function submit() {  

    if(validated()) {
      setLoading(true) 

      apiClient.post(`/api/contact`,{
        name: form.name,
        email: form.email,
        message: form.message,
      })
      .catch(function(err){         
        setError(err.response.data.message)
      })
      .then((response) => {
        if(response && response.data == 'success') {
          setForm({
            name:'',
            email:'',
            message:''
          })
          setSuccess(true);
        }
      })
      .finally(() => {
        setLoading(false)
      })
    }
    
  } 

  const validated = () => {
    if(form.name.trim()==='') return false;
    if(form.email.trim()==='') return false;
    if(form.message.trim()==='') return false;
    return true;
  }
  
  return (
      <div className="pt-12">
        <div className="flex flex-col max-w-screen-lg mx-auto my-8">          
          <div className="bg-base border border-gray-800 rounded-t-xl overflow-hidden">
            <img src="/img/faq-bg.jpeg"/>
          </div>
          {
            success === true &&
            <div className="p-3 border-2 border-green-500 bg-green-500/10 rounded-xl mt-5 mx-8 text-center">
              {t('thankyou_for_enquiry')}
            </div>
          }          
          {
            error &&
            <div className="p-3 border-2 border-red-500 bg-red-500/10 rounded-xl mt-5 mx-8 text-center">
              {error}
            </div>
          }          
          <div className="p-5 px-8 bg-base/80 border border-t-0 border-gray-800 rounded-b-xl relative leading-tight flex flex-col md:flex-row gap-5">            
            <div className="w-full md:w-2/3">
              <h4 className="text-xl font-bold mb-3">
                {t('contact_us_title')}
              </h4>
              <div className="flex flex-col md:flex-row gap-5">
                <div className="w-1/2">
                  <div onClick={() => setEnlarge(!enlarge)} className={`${enlarge ? 'scale-[2] drop-shadow-xl':'hover:scale-105'} transition-all cursor-pointer`}><img className="rounded-lg" src="/img/tt-sh-map.jpg"/></div>
                  <p className="text-center text-xs">{t('click_to_enlarge')}</p>
                </div>
                <dl className="w-1/2">
                  <dt className="text-xs tracking-widest uppercase text-red-500">{t('email')}</dt>
                  <dd className="mb-3">{t('to_email_us')}</dd>
                  <dt className="text-xs tracking-widest uppercase text-red-500">{t('phone')}</dt>
                  <dd className="mb-3">+86 173 0160 5350</dd>
                  <dt className="text-xs tracking-widest uppercase text-red-500">{t('address')}</dt>
                  <dd>{t('ttsh_address')}</dd>                  
                </dl>
              </div>
            </div>
            <form className="w-full flex flex-col gap-y-3 md:w-1/3">
              <div className="">
                <label className="block mb-1 text-red-500 text-xs uppercase tracking-widest">{t('your_name')}</label>
                <input required value={form.name} onChange={(e) => setForm({...form,name: e.target.value})} className="w-full" type="text" />
              </div>
              <div className="">
                <label className="block mb-1 text-red-500 text-xs uppercase tracking-widest">{t('your_email')}</label>
                <input required value={form.email} onChange={(e) => setForm({...form,email: e.target.value})} className="w-full" type="email" />
              </div>
              <div className="">
                <label className="block mb-1 text-red-500 text-xs uppercase tracking-widest">{t('your_message')}</label>
                <textarea required value={form.message} onChange={(e) => setForm({...form,message: e.target.value})} className="w-full h-[200px]" placeholder={t('what_can_we_help')}></textarea>
              </div>
              <button disabled={loading} onClick={() => submit()} className={`p-3 w-full font-bold rounded-lg ${loading ? 'bg-gray-500':'bg-gradient-to-t from-red-600 to-red-500'}`}>
                {
                  loading ? <Spinner className="mx-auto"/> : t('send_enquiry')
                }
              </button>
            </form>
          </div>
        </div>
      </div>
  );
}