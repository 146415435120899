import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { Spinner, apiClient, classNames } from '../../utils/helpers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function MyBookingItem(props) {
  
  const change_limit_days = 3;

  const { t, i18n } = useTranslation();
  const [detail,toggleDetail] = useState();  
  const [request,toggleRequest] = useState();
  const [error,setError] = useState(false);
  const [loading,setLoading] = useState(false);
  const [success,setSuccess] = useState(false);
  
  const [msg, setMsg] = useState({
    type:request,
    message:'',
  });

  const b = props.booking
  const btnClass = "text-sm p-1 px-2 border border-gray-500 rounded text-gray-500 hover:text-red-500 hover:border-red-500"

  const canAmend = (booking_date) => {
    const today = dayjs();    
    const bd = dayjs(booking_date);

    const diff = bd.diff(today,'d');

    if(diff >= 3) {
      return true;
    }
    
  }

  const submitRequest = () => {

    if(!msg.message) {
      alert('please enter your reason and details for the request.');
      return false;
    }

    setLoading(true) 

    apiClient.post(`/api/booking_request`,{
      bid: b.id,
      type: request,
      message: msg.message,
    })
    .catch(function(err){         
      setError(err.response.data.message)
    })
    .then((response) => {
      if(response && response.data == 'success') {
        setMsg({
          type:request,         
          message:''
        })
        toggleRequest(false);
        setSuccess(true);
      }
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return (
    <div className="pt-5">
      <p className={` ${b.status.name == 'pending' ? 'text-amber-500 border-amber-500' : b.status.name == 'cancelled' ? 'text-red-500 border-red-500' : 'text-green-500 border-green-500'} ${i18n.language == 'en' ? 'text-[10px]' : ''} uppercase p-1 px-2 border inline-block rounded mb-1`}>
        {
          b.status.name == 'pending' ?
          <>{t('upcoming_booking')}</>
          :          
          <>{t(b.status.name)}</>          
        }
      </p>                
      <h2 className={classNames(b.status.name != 'pending' ? "":"text-lg","font-bold text-gold")}>
        {dayjs(b.booking_date).format('D MMMM YYYY, dddd')}
      </h2>
      <h1 className={classNames(b.status.name != 'pending' ? "":"text-2xl","font-bold")}>
        {b.pax} {t('persons')} @ {b.seating.timeslot.name}
      </h1>
      <div className="flex gap-3 my-3">
        <button onClick={() => toggleDetail(!detail)} className={`${btnClass} ${detail ? '!text-white !border-red-500 bg-red-500':''}`}>{t('show_details')}</button>
        {
          b.status.name == 'pending' && canAmend(b.booking_date) && !success &&
          <>
            <button onClick={() => toggleRequest('change')} className={btnClass}>{t('change_booking')}</button>
            <button onClick={() => toggleRequest('cancel')} className={btnClass}>{t('cancel_booking')}</button>
          </>
        }        
      </div>

      {
        b.status.name == 'pending' && canAmend(b.booking_date) && !success &&
        <div className="text-red-500 text-sm mb-3">{t('cancellation_policy6')}</div>
      }

      {
        request &&
        <div className="">
          <h2 className="uppercase text-sm">{t(request)} {t('booking_reason')}</h2>
          <textarea required className="w-full focus:outline-none focus:ring-0" onChange={(e) => setMsg({...msg,message:e.target.value})} value={msg.message}></textarea>
          <div className="flex justify-between">
            <button className="hover:text-red-500" onClick={() => toggleRequest(false)}>{t('close')}</button>
            <button onClick={() => submitRequest()} className="p-2 px-3 rounded-lg bg-gold opacity-90 hover:opacity-100 font-bold">
            {
              loading ? <Spinner className="mx-auto"/> : t('send_request')
            }
            </button>
          </div>
        </div>
      }
      {
        success &&
        <div className="p-3 border-2 border-green-500 bg-green-500/10 rounded-xl mt-5 text-center">
          {t('thankyou_for_request')}<br/>
          {t('cancellation_policy6')}
        </div>
      }

      {
        detail &&
        <div className="grid grid-cols-3 gap-3 my-5 pb-5 border-b border-gray-800">
          <div>
            <strong className="text-gold text-xs block">{t('booking_placed_on')}</strong>
            <span>{dayjs(b.created_at).format('D MMMM YYYY, dddd')}</span>
          </div>
          <div>
            <strong className="text-gold text-xs block">{t('deposit_method')}</strong>
            <span>{b.transaction.payment_method}</span>
          </div>        
          <div>
            <strong className="text-gold text-xs block">{t('deposit_amount')}</strong>
            <span>{b.transaction.amount} {b.transaction.payment_method == 'paypal' ? 'USD': 'RMB' }</span>
          </div>        
          {/*
          <div className="col-span-3">
            <strong className="text-gold text-xs block">{t('special_requests')}</strong>
            <p>{b.note ? b.note.note : 'nil'}</p>          
          </div>
          */}
        </div>
      }      

    </div>    
  );
}