import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"
import { useState, useEffect } from 'react'
import { nav_links, nav_booking_links } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

export default function MobileNav(props) {

  const [show,toggleMob] = useState(false);
  const { t, i18n } = useTranslation();    

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('tt_lang', i18n.language);
  };

  return (
    <div className="lg:hidden ">    
      <div className="relative z-[999]">
      {
        !show ?
        <button onClick={() => toggleMob(true)} className="hover:text-red-500"><Bars3Icon className="w-8"/></button>
        :
        <button onClick={() => toggleMob(false)} className="hover:text-red-500"><XMarkIcon className="w-8"/></button>
      }  
      </div>

      {
        show &&        
        <div className="bg-[#AE2538] backdrop-blur w-screen h-screen z-30 fixed top-0 left-0 p-8 flex flex-col justify-center items-center">
          
          <div className="flex pb-3 border-b border-white/10">
            <button onClick={() => changeLang('en')} className="navBtnClass">EN</button>
            <button onClick={() => changeLang('zh')} className="navBtnClass">中文</button>
          </div>
            
          {
            nav_booking_links.map((link,i) => (
              <a key={i} href={link.href} className="mobNavBtnClass">{t(link.label)}</a>
            ))
          }
          {
            nav_links.map((link,i) => (
              <a key={i} href={link.href} className="mobNavBtnClass">{t(link.label)}</a>
            ))
          }
        </div>
      }
    </div>
  );
}