import { useState, useEffect } from 'react'
import { selectStyle, classNames, delay, Spinner, apiClient } from '../../utils/helpers';
import Countdown from 'react-countdown';
import {
  CheckIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export default function BookingStep2(props) {

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verify, setVerify] = useState(false);
  const [customer, setCustomer] = useState({    
    first_name: '',
    last_name: '',
    gender: '',
    country_code_id: '',
    phone: '',
    email: '',
    isNew: false
  });

  useEffect(() => {
    // TODO::For refresh, if customer already exists, probably should not fetchCustomer. 
    fetchCustomer()
  },[]);

  useEffect(() => {
    props.setBkinfo({
      ...props.bkinfo,
      customer_id:customer.id,
      customer:customer,
    })  
  },[customer.id]);

  async function fetchCustomer() {
    
    await apiClient.post(`/api/get_customer`,{      
      country: props.bkinfo.country,
      phone: props.bkinfo.phone,      
    })
    .catch(function(err){
      setError(err.response.data.message)
    })
    .then((response) => {      
      if(response.data) {        
        setCustomer({
          ...customer,
          id: response.data.id,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          gender: response.data.gender,
          country_code_id: response.data.country_code.id,
          phone: response.data.phone,
          email: response.data.email,
          isNew: false
        })
      } else {        
        setCustomer({
          ...customer,          
          country_code_id: props.bkinfo.country,
          phone: props.bkinfo.phone,
          isNew: true
        })
      }
    })    
  }

  async function confirmDeets() {
    
    setLoading(true)
    
    let endpoint = customer.isNew ? 'add_customer' : 'update_customer/'+customer.id

    await apiClient({
      method: customer.isNew ? 'post' : 'put',
      url: `/api/${endpoint}`,
      data: customer,
    })    
    .then((response) => {             
      if(response) {            

        setCustomer({
          ...customer,
          id: response.data
        })

        props.setBkinfo({
          ...props.bkinfo,
          last_step: 2
        })  
              
      } 
    }) 
    .catch(function(err){      
      setError(err.response.data.message)
    })
    .finally(() => {      
      setLoading(false)
    })
  }

  return (
      <>

        <h4 className="font-bold text-gold">
          {
            !customer.isNew ?
            <>
              {t('welcome_back',{
                gender: customer.gender == 'M' ? t('mr') : t('ms'),
                fname: customer.first_name,
                lname: customer.last_name
              })}
            </>
            :
            <>{t('welcome')}</>
          }
        </h4>

        <div className="flex flex-col gap-3 mt-1 mb-3">

          {
            !customer.isNew ?
            <p>{t('please_check')}</p>
            :
            <p>{t('please_fill')}</p>
          }
          

          <div className="flex flex-col md:flex-row gap-5">
            <div className="flex gap-3">
              <div className="w-1/2">
                <label className="text-xs font-bold mb-1 block">{t('fname')}</label>
                <input className="w-full" type="text" onChange={(e) => setCustomer({...customer,first_name: e.target.value})} value={customer.first_name}/>
              </div>
              <div className="w-1/2">
                <label className="text-xs font-bold mb-1 block">{t('lname')}</label>
                <input className="w-full" type="text" onChange={(e) => setCustomer({...customer,last_name: e.target.value})} value={customer.last_name}/>
              </div>
            </div>
            <div className="">
              <label className="text-xs font-bold mb-1 block">{t('gender')}</label>
              <div className="flex gap-3 md:mt-3">
                <label className="flex items-center gap-1">
                  <input type="radio" name="gender" value="M" onChange={(e) => setCustomer({...customer,gender:'M'})} checked={ customer.gender == 'M' ? true : ''} className="w-5 h-5"/>
                  <span>{t('male')}</span>
                </label>
                <label className="flex items-center gap-1">
                  <input type="radio" name="gender" value="F" onChange={(e) => setCustomer({...customer,gender:'F'})} checked={ customer.gender == 'F' ? true : ''} className="w-5 h-5"/>
                  <span>{t('female')}</span>
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-5">
            <div className="w-full md:w-1/3">
              <label className="text-xs font-bold mb-1 block">{t('phone')}</label>
              <p className="text-green-500 font-bold w-full p-1.5 px-4 border-2 border-[#414F70] bg-green-500/10 rounded">
                {`+${customer.country_code_id} ${customer.phone}`}
              </p>
            </div>
            <div className="w-full md:flex-1">
              <label className="text-xs font-bold mb-1 block">{t('email')}</label>
              <input type="text" onChange={(e) => setCustomer({...customer,email:e.target.value})} value={customer.email} className="w-full"/>
            </div>            
          </div>

          {
            error &&
            <div className="text-center text-red-500">{error}</div>
          }
          
          <button disabled={loading ? true : ''} onClick={() => confirmDeets() } className="w-full flex justify-center md:w-1/2 mx-auto bg-gold text-sm font-bold opacity-90 hover:opacity-100 p-3 px-4 mt-3 rounded-lg">
            {
              loading ?
              <>
                <Spinner />
              </>
              :
              <>
                {t('confirm')}
              </>
            }
          </button>
        </div>

        {
          verify &&
          <div className="w-full flex justify-end">
              <button className="">
                {t('next')} <ChevronRightIcon className="inline-block w-4 text-white"/>
              </button>
          </div>
        }        

      </>

  );
}