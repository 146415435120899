import { Fragment, useState, useEffect } from 'react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CheckIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import { classNames, Spinner, apiClient } from '../../utils/helpers'
import dayjs from 'dayjs'
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";
import { useTranslation } from 'react-i18next';

const Calendar = (props) => {  

  const now = dayjs();

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const { t, i18n } = useTranslation();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [currentMonth, setCurrentMonth] = useState(now);
  const [arrayOfDays, setArrayOfDays] = useState([]);
  const [active, setActive] = useState();
  const [bookedDates,setBookedDates] = useState([]);
  const [closedDates,setClosedDates] = useState([]);

  // Change this to limit the month they can book on the calendar.
  const daysInAdvanceLimit = 60;

  const nextMonth = () => {    
    if(checkNextMonthLimit()) {
      const plus = currentMonth.add(1, "month");
      setCurrentMonth(plus);
    }    
  };

  const prevMonth = () => {
    if(checkPrevMonthLimit()) {
      const minus = currentMonth.subtract(1, "month");
      setCurrentMonth(minus);
    }    
  };

  const checkNextMonthLimit = () => {
    const eom = currentMonth.endOf('month');
    const diff = eom.diff(now,'day');    
    if(diff <= daysInAdvanceLimit) {
      return true;
    }
  }

  const checkPrevMonthLimit = () => {
    const tm = now;
    const cm = currentMonth;    
    if(cm-tm > 0) {
      return true
    }
  }

  checkPrevMonthLimit();

  useEffect(() => {        
    getAvai()
    getClosed()
  },[currentMonth])

  const getAvai = async () => {
    setLoading(true)
    try {
      const response = await apiClient.get(`/api/check_full?date=${currentMonth.format('YYYY-MM-DD')}`)
      setBookedDates(response.data)      
      setError(null)
    } catch(err) {
      setError(err.message)      
      setBookedDates(null)
    } finally {
      setLoading(false)
    }    
  }

  const getClosed = async () => {
    setLoading(true)
    try {
      const response = await apiClient.get(`/api/check_closed?date=${currentMonth.format('YYYY-MM-DD')}`)
      setClosedDates(response.data)      
      setError(null)
    } catch(err) {
      setError(err.message)      
      setClosedDates(null)
    } finally {
      setLoading(false)
    }    
  }

  const checkAvailability = (day) => {
    let formatted_day = dayjs(day).format('YYYY-MM-DD')        
    let result = bookedDates.find((d) => d == formatted_day)
    if(result) {        
      return result
    }    
  }

  const checkClosed = (day) => {
    let formatted_day = dayjs(day).format('YYYY-MM-DD')        
    let result = closedDates.find((d) => d == formatted_day)
    if(result) {        
      return result
    }    
  }
  
  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";

    return (
      <header className="flex items-center justify-center py-2">
        
        <div className="flex items-center gap-3">
          {
            checkPrevMonthLimit() &&
            <button onClick={() => prevMonth()} className="hover:text-red-500"><ChevronLeftIcon className="h-5 w-5" aria-hidden="true" /></button>
          }
          <h1 className="text-lg font-semibold text-white">
            <time dateTime={ currentMonth.format(dateFormat) }>{ currentMonth.format(dateFormat) }</time>
          </h1>
          {
            checkNextMonthLimit() &&
            <button onClick={() => nextMonth()} className="hover:text-red-500"><ChevronRightIcon className="h-5 w-5" aria-hidden="true" /></button>
          }          
        </div>
        
      </header>
    );
  };

  const renderDays = () => {
    const dateFormat = "ddd";    
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div key={i} className="py-2">{now.weekday(i).format(dateFormat)}</div>          
      );
    }
    return <div className="bg-gray-800 grid grid-cols-7 gap-px border-b border-gray-700 text-center text-xs font-semibold leading-6 text-white rounded-t-lg">{days}</div>;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();    

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);

      weekDates.push(formated);

      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }

    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
  }, [currentMonth]);

  const renderCells = () => {
    const rows = [];
    let days = [];

    arrayOfDays.forEach((week, index) => {      
      week.dates.forEach((d, i) => {

        let isFull = checkAvailability(d.year+'-'+d.month+'-'+d.day)        
        let isClosed = checkClosed(d.year+'-'+d.month+'-'+d.day)

        days.push(
          <button
            className={`py-2 px-3 h-[50px] md:h-[80px] m-0.5 rounded flex bg-gray-800 text-white relative ${
              !d.isCurrentMonth || d.isBeforeToday ? "text-gray-700 bg-gray-700/20" : 
              active == d ? "bg-green-500/50": 
              isFull || isClosed ? "bg-gray-800/50"
              :"hover:bg-gray-700"
            }`}
            disabled={ !d.isCurrentMonth || d.isBeforeToday || isFull || isClosed ? true : '' }
            key={i}
            onClick={() => {
              props.setBookingDate(d)
              setActive(d)
            }}
          >            
            {
              active == d ?
              <div className="absolute w-full h-full top-0 left-0 flex justify-center items-center"><CheckIcon className="w-5 text-white"/></div>
              :
              d.isCurrentDay ?
              <div className="w-5 h-5 flex items-center justify-center font-bold p-3 bg-blue-500 rounded-full"><span>{d.day}</span></div>
              :
              <span className="number">{d.day}</span>
            }            
            {
              isFull && !isClosed &&
              <div className="absolute bottom-1 left-2 sm:left-auto sm:right-2">              
                <span className="text-red-500 text-[8px] sm:text-[10px]">{t('full')}</span>
              </div>              
            }            
            {
              isClosed &&
              <div className="absolute bottom-1 left-2 sm:left-auto sm:right-2">              
                <span className="text-gray-500 text-[8px] sm:text-[10px]">{t('closed')}</span>
              </div>              
            }            
          </button>
        );
      });
      rows.push(
        <div key={index} className="w-full grid grid-cols-7">
          {days}
        </div>        
      );
      days = [];
    });

    return <div className="flex flex-col p-1 bg-gray-900 text-xs leading-6 text-gray-700 rounded-b-lg">{rows}</div>;
  };

  const formateDateObject = date => {
    const clonedObject = { ...date.toObject() };

    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months+1, // For some reason, dayjs month in date object is 1 less than actual.
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
      isBeforeToday: date.isBefore(now)
    };

    return formatedObject;
  };

  return (
    <div className="">
      
      {renderHeader()}

      {
        loading ? 
        <div className="w-full mt-5 flex justify-center"><Spinner/></div> 
        :
        <>
        {renderDays()}

        {renderCells()}
        </>
      }
        
            
    </div>
  )
}

export default Calendar;