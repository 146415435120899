import { classNames } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

export default function StepsIndicator(props) {

  const { t, i18n } = useTranslation();
  const step = props.step

  const titles = [
    '',
    t('booking_step1'),
    t('booking_step2'),
    t('booking_step3'),
    t('booking_step4'),
  ]

  return (
    <>
      <h4 className="text-xl font-bold">
        {titles[step]}
      </h4>
      <div className="grid grid-cols-5 gap-1 my-3">
        <div className="bg-amber-400 h-1"></div>
        <div className={classNames(step >= 2 ? "bg-amber-400":"bg-gray-700","h-1")}></div>
        <div className={classNames(step >= 3 ? "bg-amber-400":"bg-gray-700","h-1")}></div>
        <div className={classNames(step >= 4 ? "bg-amber-400":"bg-gray-700","h-1")}></div>
        <div className={classNames(step >= 5 || props.completed ? "bg-amber-400":"bg-gray-700","h-1")}></div>
      </div>
    </>
  );
}