import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { classNames, Spinner, apiClient } from '../../utils/helpers';
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  XMarkIcon
} from '@heroicons/react/20/solid';

export default function BookingDatePane(props) {
  
  dayjs.extend(customParseFormat)

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [slots,setSlots] = useState([]);
  const [error,setError] = useState();
  const [loading,setLoading] = useState(false);
  const [loading2,setLoading2] = useState(false);
  const [max,setMax] = useState([]);

  useEffect(() => {
    getSlots(props.chosenDate)
  }, [props.chosenDate]);

  const getSlots = async (date) => {
    setLoading(true)
    try {
      const response = await apiClient.get(`/api/get_slots?date=${props.chosenDate.format('YYYY-MM-DD')}`)      
      setSlots(response.data.slots)
      setMax(response.data.max)
      setError(null)
    } catch(err) {
      setError(err.message)
      setMax(null)
      setSlots(null)
    } finally {
      setLoading(false)
    }
  }
  
  function startBooking(seating,pax)
  {
    setLoading2(true)
    // Post booking date and pax to API, save booking holding, return booking ID.
    try {
      setError(null)
      
      apiClient.get('/sanctum/csrf-cookie').then(res => {

        apiClient.post(`/api/start_booking`,{        
          d: props.chosenDate.format('YYYY-MM-DD'),
          s: seating,
          p: pax,        
        }).then(response => {
          if(response.data.code) {
            navigate(`/booking_process/${response.data.code}`)
          }
        })        

      })
      
    } catch(err) {
      setError(err.message)      
    } finally {
      setLoading2(false)
    }
  }

  return (
   <div className={classNames(loading2 ? "overflow-hidden":"","absolute lg:relative w-full lg:w-1/3 h-[calc(100vh-200px)] bg-[#181C26] rounded-lg lg:mt-10")}>
      {
        loading2 &&
        <div className="absolute flex justify-center items-center w-full h-full top-0 left-0 bg-black/10 backdrop-blur z-30">
          <Spinner className="!w-10"/>
        </div>
      }      
      <button onClick={() => props.setChosenDate(false)} className="absolute top-0 right-0"><XMarkIcon className="w-8 h-8"/></button>
      <div className="p-5">
       <h4 className="text-amber-300 font-bold mb-1">{props.chosenDate.format('D MMMM YYYY, dddd')}</h4>
       <ol className="text-sm list-decimal ml-4">
         <li className="mb-3">{t('booking_pane1')}</li>
         <li>{t('booking_pane2',{max_pax:max})}</li>
       </ol>
      </div>

     <div className="flex flex-col gap-y-5 mb-5 overflow-y-auto scrollable h-[calc(100vh-380px)] px-5 pr-3">       
      {
        loading ? 
        <div className="w-full h-full justify-center items-center"><Spinner/></div>
        :
        slots.map((t,index) => (
          <div key={index} className="border-b border-gray-700 pb-5">
            <h4 className="font-bold mb-1">{dayjs(t.timeslot,'HHmm').format('HH:mm')}</h4>
            <div className="flex flex-wrap gap-2">
              {
                [...Array(max)].map((e,i) => {
                  if(!t.booked || i < (max-t.booked)) {
                    return <button onClick={() => startBooking(t.seating_id,i+1) } className="p-2 rounded-full bg-gray-800 hover:bg-gray-700 w-12 h-12 sm:w-8 sm:h-8 flex justify-center items-center border border-gray-700 hover:scale-125 transition" key={i}>{i+1}</button>
                  } else {
                    return <div className="opacity-20 p-2 rounded-full bg-gray-800 w-12 h-12 sm:w-8 sm:h-8 flex justify-center items-center border border-gray-700" key={i}>{i+1}</div>
                  }                          
                })
              }
            </div>
          </div>
        ))
      }       
     </div>
   </div>
  );
}