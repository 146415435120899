import { useState, useEffect } from 'react'
import { classNames, apiClient, Spinner } from '../../utils/helpers';
import {
  XMarkIcon
} from '@heroicons/react/20/solid';
import QRCode from "react-qr-code";
import { useTranslation } from 'react-i18next';

export default function WechatPay(props) {

  const { t, i18n } = useTranslation();
  const total_fee = props.bkinfo.price * props.bkinfo.slots;
  const [qrcode,setQrcode] = useState();

  /* Payment states */    
  const [orderID, setOrderID] = useState(false);

  const createOrder = () => {    
    props.setPaying(true)
    return apiClient.post(`/api/wpay`,{
      'code' : props.bkinfo.code,
    })
    .catch(function(err){
      console.log(err)
    })
    .then((response) => {            
      if(response.data.code_url) {
        console.log(response.data.code_url)
        setQrcode(response.data.code_url)        
        checkStatus()
      }
      if(response.data.code == 'ORDERPAID') {
        props.setSucceeded(true)
      }
    })
  }  

  const checkStatus = () => {    
    const checking = setInterval(function(){      
      apiClient.get('/api/check_wx/' + props.bkinfo.code)    
      .then((response) => {                    
        if(response.data && response.data == 'SUCCESS') {
          clearInterval(checking)
          props.setSucceeded(true)          
        }
      })  
    },3000)    
  }

  const CancelPay = () => {
    props.setPaying(false)
    setQrcode(false)
  }

  return (
      <>
        <button disabled={props.paying} onClick={() => createOrder()} className={classNames(props.paying ? "bg-green-800/50":"bg-white","w-full h-[100px] rounded-xl flex justify-center items-center opacity-90 hover:opacity-100")}>
          {
            props.paying ?
            <Spinner className="w-9"/>
            :
            <img src='/img/wechat.svg'/>
          }
        </button>              
        {
          qrcode &&
          <div className="fixed top-0 left-0 w-screen h-screen bg-black/80 backdrop-blur z-[999]">
            <button onClick={() => CancelPay()} className="absolute top-3 right-3 hover:text-red-500"><XMarkIcon className="w-12 h-12"/></button>
            <div className="w-full h-full flex flex-col justify-center items-center gap-3">
              <h2 className="px-8 font-bold text-lg">{t('please_scan_code')}</h2>
              <em className="text-red-500">{t('encounter_problems')}</em>
              <QRCode value={qrcode} />
            </div>            
          </div>
        }
      </>

  );
}