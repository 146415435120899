import { useState, useEffect } from 'react'
import { selectStyle, classNames, delay, Spinner } from '../../utils/helpers';
import Countdown from 'react-countdown';
import {
  CheckIcon,
  ChevronRightIcon
} from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

export default function BookingStep3(props) {

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [verify, setVerify] = useState(false);
  const [requests, setRequests] = useState({
    notes: '',
    food: [],
    food_other: '',
    occasion: [],
    occasion_other: ''
  });

  const handleFood = (e) => {
    const {value,checked} = e.target;
    const { food } = requests;

    if(checked) {
      setRequests({
        ...requests,
        food: [...food, value]
      })
    }
    else {
      setRequests({
        ...requests,
        food: food.filter((e) => e !== value)
      })
    }
  }

  const handleOccasion = (e) => {
    const {value,checked} = e.target;
    const { occasion } = requests;

    if(checked) {
      setRequests({
        ...requests,
        occasion: [...occasion, value]
      })
    }
    else {
      setRequests({
        ...requests,
        occasion: occasion.filter((e) => e !== value)
      })
    }
  }

  const occasions = [
    'Birthday',
    'Anniversary',
  ]

  const diet = [
    'No Pork',
    'No Beef',
    'No Intestines',
    'No raw food/seafood',
    'Gluten Free',
    'Vegetarian Menu'
  ]

  async function confirmDeets() {
    
    props.setBkinfo({
      ...props.bkinfo,
      notes:requests.notes,
      food:requests.food + ' / ' + requests.food_other,      
      occasion:requests.occasion + ' / ' + requests.occasion_other,      
      last_step: 3})  

  }

  return (
      <>

        <h4 className="font-bold text-gold">
          {t('thank_you_guest',{
            gender: props.bkinfo.customer.gender == 'M' ? t('mr') : t('ms'),
            fname: props.bkinfo.customer.first_name,
            lname: props.bkinfo.customer.last_name
          })}                    
        </h4>

        <div className="flex flex-col gap-3 mt-1 mb-3">

          <p>{t('any_special_requests')}</p>

          <textarea onChange={(e) => setRequests({...requests,notes:e.target.value})}></textarea>

          <div className="">
            <h4 className="text-gold font-bold mb-1">{t('food_restrictions')}</h4>
            <div className="flex gap-x-5 gap-y-1 flex-wrap items-center">
              {
                diet.map((a,b) => (
                  <label key={b} className="block">
                    <input type="checkbox" onChange={handleFood} value={a} /> <span>{a}</span>
                  </label>
                ))
              }   
              
              <input className="w-full md:w-auto" type="text" onChange={(e) => setRequests({...requests,food_other:e.target.value})} name="" placeholder={t('other_food')} />

            </div>

            <h4 className="text-gold font-bold mt-3">{t('special_occasion')}</h4>
            <div className="flex gap-x-5 gap-y-1 flex-wrap items-center">
              {
                occasions.map((a,b) => (
                  <label key={b} className="block">
                    <input type="checkbox" onChange={handleOccasion} value={a} /> <span>{a}</span>
                  </label>
                ))
              }   
              <input className="w-full md:w-auto" type="text" onChange={(e) => setRequests({...requests,occasion_other:e.target.value})} name="" placeholder={t('other_occasion')} />         
            </div>
          </div>
          
          <button disabled={loading ? true : ''} onClick={() => confirmDeets() } className="w-full flex justify-center md:w-1/2 mx-auto bg-gold text-sm font-bold opacity-90 hover:opacity-100 p-3 px-4 mt-3 rounded-lg">
            {
              loading ?
              <>
                <Spinner />
              </>
              :
              <>
                {t('proceed_payment')}
              </>
            }
          </button>
        </div>

       
      </>

  );
}