import axios from "axios";


export const PAYPAL_CLIENT_ID = "Ab3Z5OjRgCVBASd55BRU6rKUl7n6tVa8XGb8IbtLQgSfLvRb6kiGuk5HokRMXcPtWFQyMoTBfL-QMFTy"

export const apiClient = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? "http://localhost":"https://taian-table.com",
    withCredentials:true
})

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function getSiteStatus() {
    apiClient.get(`/api/get_status`).then((response) => {
        return response.data;
    })
}

export const nav_links = [
    {
        label: 'nav_about',
        href: 'https://sh.taian-table.cn'
    },
    {
        label: 'nav_faq',
        href: '/faq'
    },
    {
        label: 'nav_contact',
        href: '/contact'
    },
];

export const nav_booking_links = [
  { href: '/booking' , label: 'new_booking' },
  { href: '/my_bookings' , label: 'check_your_bookings' },
]

export const selectStyle = {
    control: (provided, state) => ({
        ...provided,
        color: 'white',
        background: '#293247',
        border: '2px solid #414F70',
        borderRadius: '7px',
        "&:hover": {
            border: '2px solid #414F70',
        }
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        background: state.isSelected ? '#8B8444' : 'transparent',
        color: 'white',
        "&:hover": {
            background: '#8B8444',
        }
    }),
    input: base => ({
        ...base,
        color: "white",
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontWeight: 'bold',
        color: 'white',        
    }),
    menuList: (base) => ({
        ...base,
        width: "100%",
        minWidth: "200px",
        background: '#293247',
        "::-webkit-scrollbar": {
            width: "6px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
            background: "#fff",
            borderRadius: "20px",
        },
        "::-webkit-scrollbar-thumb": {
            background: "#434AA8",
            borderRadius: "20px",
        },
        "::-webkit-scrollbar-thumb:hover": {
            background: "#555",
        },
    }),
};

export const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export const Spinner = (props) => {
  return <svg className={`${props.className} w-4 animate-spin text-white fill-current`} viewBox="0 0 512 512"><path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z"/></svg>
}