import { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { classNames, Spinner, apiClient } from '../utils/helpers';
import ReactSelect from 'react-select';
import Countdown from 'react-countdown';
import {
  InformationCircleIcon
} from '@heroicons/react/20/solid';
import ImportantNotes from '../components/ImportantNotes';

export default function BookingResult(props) {

  const [step,setStep] = useState(1);
  const [count,setCount] = useState(Date.now())
  const [loading,setLoading] = useState(true);
  const [booking,setBooking] = useState();
  
  let [params] = useSearchParams();

  const returnData = {
    method:params.get('method') ? params.get('method') : 'desktop',
    notify_id:params.get('notify_id'),
    out_trade_no:params.get('out_trade_no'),
    trade_no:params.get('trade_no'),
    trade_status:params.get('trade_status'),
    total_fee:params.get('total_fee') ? params.get('total_fee') : params.get('total_amount'),
  }

  useEffect(() => {
    apiClient.post(`/api/alipay_verify`,
      returnData
    )
    .catch(function(err){
      console.log(err)
    })
    .then((response) => {            
      if(response.data.status === 'success') {
        setLoading(false);
        setBooking(response.data.booking);
      }
    })  
  },[]);  

  return (
    <div className="pt-12">

      <div className="flex flex-col max-w-screen-md mx-auto my-8">

        <h4 className="text-xl font-bold">
          { loading ? 'Booking Processing...' : 'Booking Confirmed' }
        </h4>
        <div className="grid grid-cols-5 gap-1 my-3">
          <div className="bg-amber-400 h-1"></div>
          <div className="bg-amber-400 h-1"></div>
          <div className="bg-amber-400 h-1"></div>
          <div className="bg-amber-400 h-1"></div>
          <div className="bg-amber-400 h-1"></div>
        </div>
        
        <div className="p-5 px-8 bg-base border border-gray-800 rounded-xl relative">          

          {
            !loading &&
            <div className="mt-5 text-center">          

              <h4 className="text-xl font-bold text-green-500">感谢您 Thank you, {booking.gender == 'M' ? 'Mr.' : 'Ms.'} {booking.first_name} {booking.last_name}.<br/>您的预订已完成。 Your Booking is Confirmed.</h4>
              <p>
                您的预订已完成！我们已发送确认邮件和短信给您。若您未收到邮件和短信确认 请联系我们 17301605350<br/>
                An email with your booking details has also been sent to the email address you entered. If you do not receive a SMS or confirmation email, please contact us at 17301605350.                
              </p>

              <div className="flex flex-col items-center my-3">
                <p>期待很快见到您 We look forward to your visit on:</p>

                <div className="text-center my-3">
                  <h2 className="text-lg font-bold text-gold">{dayjs(booking.booking_date).format('D MMMM YYYY, dddd')}</h2>
                  <h1 className="font-bold text-2xl">{booking.pax} Persons at {booking.timeslot}</h1>
                </div>

                <p>
                  您也可以到我们官网查看更多信息。 You may visit our website at <a className="text-gold" href="https://sh.taian-table.cn">https://sh.taian-table.cn</a> for more information.
                </p>

              </div>
              
            </div>
          }

          {
            loading &&
            <div className="flex flex-col items-center justify-center my-5">
              <h4 className="text-xl font-bold text-green-500">请稍等 Please wait a moment</h4>
              <p className="mb-3">
                您的付款和预订正在处理中。
                Your payment and booking is being processed.
              </p>
              <Spinner className="!w-8 text-yellow-500" />
            </div>
          }
          

          <ImportantNotes/>

        </div>        

      </div>

    </div>
  );
}