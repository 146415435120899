import { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import nl2br from "react-nl2br";
import { classNames, Spinner, apiClient } from '../utils/helpers';
import { useTranslation } from 'react-i18next';

export default function Faq(props) {

  const { t, i18n } = useTranslation();

  useEffect(() => {    
    fetchFaqs()
  },[]);  

  const fetchFaqs = () => {
    setLoading(true)
    apiClient.get('/api/fetch_faq')
      .then((response) => {
        if(response.data) {
          setFaqs(response.data)
        }
    }).finally(() => {
      setLoading(false)
    })
  }

  const [faqs,setFaqs] = useState()
  const [loading,setLoading] = useState()

  const FaqItem = (props) => {
    
    const [show,toggleShow] = useState(false)    

    return (
      <div className="">
        <h1 className="cursor-pointer text-red-500 tracking-widest hover:text-red-400 mb-2" onClick={() => toggleShow(!show)}>{props.label}</h1>
        <div className={`${show ? 'opacity-100 h-auto mb-5' : 'opacity-0 h-[0px] pointer-events-none'} transition-all`}>
          {nl2br(props.children)}
        </div>
      </div>
    )
  }

    return (
      <div className="pt-12">
        <div className="flex flex-col max-w-screen-lg mx-auto my-8">          
          <div className="bg-base border border-gray-800 rounded-t-xl overflow-hidden">
            <img src="/img/faq-bg.jpeg"/>
          </div>
          <div className="p-5 px-8 bg-base/80 border border-t-0 border-gray-800 rounded-b-xl relative leading-tight flex flex-col items-start md:flex-row gap-5">

            <div className="w-full">

              <h4 className="text-xl font-bold mb-3">
                {t('faq_heading')}
              </h4>

              <h1 className="tracking-widest text-red-500">{t('faq_title')}</h1>
              <div className="mb-5">
                <p className="mb-3">{t('faq_intro')}</p>

                <p className="mb-3">{t('faq_intro2')}</p>
              </div>

              <div className="flex flex-col gap-3 my-3">
              {
                loading ?
                <Spinner/>
                :
                faqs &&
                faqs.map((e,i) => (
                  <FaqItem key={i} label={i18n.language == 'en' ? e.question_en : e.question_zh}>
                    {i18n.language == 'en' ? e.answer_en : e.answer_zh}
                  </FaqItem>
                ))
              }                
              </div>
            </div>            

          </div>
        </div>
      </div>
    );
  }